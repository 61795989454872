<template>
    <div>
        <h1 class="oleo">Twijfel je over een voorstel?</h1>

        <h2 class="oleo">Do's</h2>
        <h3 class="oleo-black">Heb vertrouwen in de matchmakers</h3>
        <p>
            De matchmakers doen nooit zomaar een voorstel. Je kunt erop vertrouwen dat er goede redenen zijn om deze
            persoon aan jou voor te stellen. Ook als jij de overeenkomsten niet meteen uit de persoonsbeschrijving kunt
            halen, kun je ervan uitgaan dat het de moeite waard is om het contact aan te gaan. Gebruik een
            persoonsbeschrijving dus niet om zelf opnieuw te selecteren. Dat heeft je matchmaker al voor jou gedaan. En
            juist dat is het voordeel van bemiddeling.
        </p>

        <h3 class="oleo-black">Bij twijfel doen</h3>
        <p>
            Geef iemand altijd het voordeel van de twijfel. Pas als je iemand ziet en spreekt, kun je echt een oordeel
            vormen. Daarbij gaat het in de liefde om de chemie tussen twee mensen. Aan de hand van een
            persoonsbeschrijving kun je niet voorspellen of de klik er wel of niet is. Dit kun je alleen zelf ervaren
            door met iemand af te spreken.
        </p>

        <h3 class="oleo-black">Ga na waar de twijfel vandaan komt</h3>
        <p>
            Ga bij jezelf na waar de twijfel precies vandaan komt. Komt het door iets wat je van de ander invult? Besef
            dan dat je niet voor een ander kunt denken. Is het een detail dat je niet aanspreekt? Dan ben je misschien
            te kritisch; de perfecte partner bestaat niet. Het kan ook dat je (onbewust) bang bent om teleurgesteld of
            afgewezen te worden. Bedenk dan waar je het uiteindelijk voor doet. Om de liefde een kans te geven, moet je
            een risico durven nemen.
        </p>

        <h3 class="oleo-black">Neem contact op met je matchmaker voordat je een voorstel afwijst</h3>
        <p>
            Heb je sterk het gevoel dat het voorstel niet bij jou past en overweeg je afwijzend te reageren? Neem dan
            altijd eerst contact op met je matchmaker. Er is een goede reden waarom juist deze persoon aan jou wordt
            voorgesteld. Als je te snel iemand afwijst, loop je een groot risico op een gemiste kans. Overleg met je
            matchmaker als je twijfelt. Hij/zij kent de persoon achter het voorstel en kan advies geven op basis van
            jouw twijfels.
        </p>

        <h3 class="oleo-black">Richt je op de overeenkomsten</h3>
        <p>
            Kritisch zijn is goed. Het gaat tenslotte om een potentiële partner. Maar een groot risico van het lezen van
            een profiel voordat je iemand hebt ontmoet, is té kritisch zijn. Verschillen lijken snel uitvergroot op
            papier. Focus je bewust op de overeenkomsten in plaats van de verschillen. Bedenk waarom iemand wel bij jou
            zou kunnen passen.
        </p>

        <h3 class="oleo-black">Houd de perfecte relatie in je achterhoofd, niet de perfecte partner</h3>
        <p>
            Misschien heb je een lijstje eigenschappen in je hoofd waaraan jouw partner moet voldoen. Ideale uiterlijke
            kenmerken, karaktereigenschappen, hobby’s etc. Bedenk dat een relatie ontstaat uit dat wat er tussen twee
            personen gebeurt. En dat elke relatie uniek is. Je kunt je dus beter richten op wat je in een relatie zoekt,
            in plaats van in een partner. Het is zonde om kansen mis te lopen in de zoektocht naar een relatie. Er kan
            een geweldige relatie ontstaan met iemand waarvan je het in eerste instantie niet meteen had gedacht!
        </p>

        <h2 class="oleo">Dont's</h2>
        <h3 class="oleo-black">Trek nog geen conclusies</h3>
        <p>
            We zijn van nature geneigd snel conclusies te trekken. Maar vaak blijken deze conclusies niet te kloppen als
            we iemand leren kennen. Bedenk dat je niet kunt oordelen op basis van zo weinig informatie. Laat eigen
            interpretaties dus nooit een reden zijn om niet met iemand in contact te komen. Probeer de
            persoonsbeschrijving te lezen zonder conclusies te trekken over iemands karakter, interesses of
            persoonlijkheid. Lukt het niet om geen conclusies te trekken bij het lezen van de persoonsbeschrijving?
            Onthoud dan dat je altijd beter bij degene zelf kunt navragen of jouw conclusies ook echt kloppen.
        </p>

        <h3 class="oleo-black">Richt je niet alleen op de verschillen</h3>
        <p>
            Bij het lezen van een persoonsbeschrijving gaat de aandacht al snel naar de verschillen. Je hebt misschien
            de neiging om te kijken waarom iemand niet bij jou past. Onthoud dat negatieve punten op papier zwaarder
            wegen dan positieve. Kijk hoe iemand in het echt is en ga eventueel in gesprek over de punten die jij minder
            vindt. Vaak blijken die negatieve punten helemaal niet zo aanwezig te zijn als je had gedacht.
        </p>

        <h3 class="oleo-black">Denk niet voor de ander</h3>
        <p>
            Net zoals je geen conclusies kunt baseren op een persoonsbeschrijving, kun je ook niet weten wat de ander
            denkt of wil. Vul dus niet in wat een ander over jou zal denken. Denken dat de ander jou toch niet leuk zult
            vinden, te hoog gegrepen is, in bescherming genomen moet worden of andere invullingen van andermans
            gedachten, zijn géén goede redenen om niet in contact te komen. Je kunt dit nooit zeker weten en ontneemt
            jezelf hierdoor misschien de mogelijkheid tot een mooie ontmoeting.
        </p>

        <h3 class="oleo-black">Laat de foto niet de doorslag geven</h3>
        <p>
            Ga bij jezelf na in welke mate jij je laten leiden door een plaatje. Trek jij op basis van de foto de
            conclusie of het een match zou kunnen zijn? De foto is een momentopname en soms verraderlijk; iemand kan
            heel verschillende op foto’s staan en overkomen. De profieltekst is een beschrijving van iemands
            persoonlijkheid. Hiermee kun je dus beter inschatten of iemand bij jou zou kunnen passen. De manier van
            spreken en doen, oogopslag en houding zijn niet uit een foto te halen. Laat je hier niet door leiden.
        </p>
    </div>
</template>

<script>
    export default {
        data() {
            return {};
        },
        mounted() {
            scrollTo(0, 0);
        },
    };
</script>
